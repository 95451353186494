import React, { useState } from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

import { Helmet } from "react-helmet";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";


import "../../scss/main.scss";
import "../../scss/_blog.scss";

const title = 'Terms and Conditions'

export default function TermsAndConditions(props) {

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Edyn Care | {title}</title>
                <meta property="og:title" content={`Edyn Care | ${title}`} />
                <meta property="og:description" content="High quality care in the comfort of your own home." />
                <meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
                <meta property="og:url" content={`https://www.edyn.care/${props.path}`} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="edyn.care" />
                <meta property="twitter:url" content={`https://www.edyn.care/${props.path}`} />
                <meta name="twitter:title" content={`Edyn Care | ${title}`} />
                <meta name="twitter:description" content="High quality care in the comfort of your own home." />
                <meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
            </Helmet>
            <div className="container">
                <Header />
                <main className="blog-post">
                    <div className="blog-post__above-the-fold">
                        <StaticImage
                            src="../../static/assets/marketing-site/images/our-approach-01.png"
                            objectFit='contain'
                            placeholder="blurred"
                            loading="eager"
                        />
                        <div>
                            <h1 className="blog-post__above-the-fold-title">Terms and Conditions</h1>
                            <p className="blog-post__above-the-fold-published">8th June 2018</p>
                        </div>
                        <StaticImage
                            src="../../static/assets/marketing-site/images/our-approach-02.png"
                            objectFit='contain'
                            placeholder="blurred"
                            loading="eager"
                        />
                    </div>
                    <section className="blog-post-section">
                        <div className="blog-post__content privacy-policy__content">
                            <h2>Definitions</h2>
                            <p><strong>“Agreement” </strong>means the agreement between us and you for the provision of the Services which includes these Terms and any variations agreed in the Care Plan;</p>
                            <p><strong>“Care Plan”</strong> means an online written document, risk and care needs assessment which has been signed by you or on your behalf, as amended from time to time.</p>
                            <p><strong>“Carer”</strong> means the individuals working and delivering care services, in reference to this a “Carer” shall include a <strong>"Live-in-Carer"</strong> (unless otherwise specified). Where more than one individual is providing the the service, “<strong>Carer</strong>” should be read as “<strong>Carers</strong>”.</p>
                            <p><strong>“Complaints Procedure”</strong> means the complaints procedure contained in the Service User Guide (as varied from time to time).</p>
                            <p><strong>“Edyn.Care”</strong> or <strong>“Service”</strong> means this agreement between You and Us and all documents referred to in this agreement, including Care Plan and Technology tools.</p>
                            <p><strong>“Events Outside Our Control”</strong> means any act or event beyond our reasonable control;</p>
                            <p><strong>“Fees”</strong> means the fees for the Service  notified to You initially in the Care Plan, which is amended from time to time.</p>
                            <p><strong>“Head of Care”</strong> means the individual notified to You in the Service User Guide or such other head of care as notified to You from time to time.</p>
                            <p><strong>“Home”</strong> means Your home address.</p>
                            <p><strong>“Introductory Visit”</strong> means Our first Visit to You during which We shall agree with You the Care Plan and Our Fees.</p>
                            <p><strong>“Representative”</strong> means individual who oversees care - family member, friend and where applicable, any other appropriate external social or health care professional</p>
                            <p><strong>“Visit”</strong> means the activity of Our Carer entering Your home and providing the services outlined in Your Care Plan</p>
                            <p><strong>“Online Medication Form”</strong> means the form on which Our Carers record any medication provided to You as part of the Services.</p>
                            <p><strong>“Platform”</strong> Our data storage platform which can be accessed at edyn.care or such other address notified to You from time to time.</p>
                            <p><strong>“Personal Data”</strong> this term shall have the same meaning as in the Data Protection Act 1998.</p>
                            <p><strong>“Public Holiday Rate”</strong> the charge for the provision of Services on Public Holidays;</p>
                            <p><strong>“Public Holidays” </strong>public holidays in England</p>
                            <p><strong>“Service”</strong> or <strong>“Services” </strong>the tailored home care service for You provided by Us at Your Home, including services outside the confinement of Your Home. </p>
                            <p><strong>“Service User Guide” </strong>the guide provided by Us to You in respect of the Services to be provided on or around the date of this Agreement.</p>
                            <p><strong>“Statutory Regulator”</strong> where the Service You require is subject to regulation, We are required to be registered with Care Quality Commission (CQC). Contact details for the Statutory Regulator are provided in the Service User Guide, and upon request.</p>
                            <p><strong>“Us”</strong>,<strong> “We” </strong>and<strong> “Our” </strong>Age Abode (edyn.care), registered in England and Wales with company number x, who registered office is x trading as “edyn.care)</p>
                            <p>When we use the words <strong>“writing”</strong> or <strong>“written”</strong> in the Terms, this will include email unless we say otherwise</p>

                            <h2>2. About edyn.care</h2>
                            <p>2.1 Age Abode Limited (operating as edyn.care) is pleased to makes its Service, as described below, available to you on the following terms and conditions. The Terms of Service are a legal Agreement between the individual using the Service (“You”) and Age Abode Limited (also referred to in these Terms as “We”, “Us” or “Our”). The Website is owned and operated by Age Abode Limited trading as Edyn Care. We are a company registered in England and Wales with the company number 10713673.</p>
                            <p>2.2 Age Abode Limited is a company with registered and operating offices at 309 Battersea Park Library,  SW11 4NF.</p>
                            <p>Emai: hello@edyn.care Phone: 020 3970 9900 Website: <a href="http://www.edyn.care">www.edyn.care</a></p>
                            <p>2.3 edyn.care is an online tech-enabled home care service that provides hand-selected carers, supported by cutting edge technology. We use technology and the human touch to make home the best choice and provide the tools needed to help You manage the care directly.</p>
                            <p>2.4  By using Edyn Care you confirm that</p>
                            <ul>
                                <li>You have the right to form legally binding agreements under UK law</li>
                                <li>All the information provided by you on the site is correct and accurate, including any stated care requirements or specific needs.</li>
                                <li>If you are providing information for another individual you have permission to do so</li>
                            </ul>
                            <h2>3. Regulatory information</h2>
                            <p>3.1 Age Abode (operating as edyn.care) is approved and regulated by the Care Quality Commission (CQC), with registered number 1-5175396170. </p>
                            <h2>4.  Terms Governing Service Use</h2>
                            <p>4.1 You can only use this Website and Service if you accept these Terms and Conditions (“Terms”), which include our Privacy Policy (available at www.edyn.care/privacy), which govern your use of the Service, so please read and abide by them. By using the Service, you agree you have read, understood and agree to be bound by the edyn.care “Terms” and “Privacy policy”. Please check this page from time to time to take notice of any changes we make, as they are binding on you. If you do anything in breach of these Terms we may terminate Your account and right to use our Platform. </p>
                            <p>4. 2 This Agreement is governed by English law and you agree to the exclusive jurisdiction of the courts of England and Wales. </p>
                            <h2>5. How it works</h2>
                            <p>5.1 Whether it’s support with day-to-day living or slightly more hands on care you’re looking for, we aim to help everybody get the very most from their later years. Our service will offer care to meet Your needs and preferences as stated in your Care Plan. Our process is as follows:</p>
                            <ol className="alpha">
                                <li>One of our care specialist will call You and find out further information about Your care needs and interests. </li>
                                <li>One of our team will Visit You in Your Home to discuss and identity Your care requirements before We commence the Service;</li>
                                <li>Work with You, Your family and any appropriate external professionals to carry out an assessment of Your needs and preferences for care and agree and prepare you Care Plan;</li>
                                <li>Assess the risks to Your health and safety of receiving care and reasonably mitigate issues;</li>
                                <li>Endeavour to provide Carers who not only meet your Care Plan requirements but also have similar interests to you;</li>
                                <li>Endeavour to to provide you with information about your Carers, including details and profiles of these Carers;</li>
                                <li>Agree the frequency and timings of Our Visits to You. If You need to change from such agreed times You will need to inform Us two weeks before;</li>
                                <li>We then allocate a Edyn Care Specialist who oversees the support provided. On a day to day basis, the coordination of care is undertaken by the Edyn Care team who are located in our Battersea offices. </li>
                                <li>Ensure You are informed about all important decisions that affect You, and when You are unable ensure that Your representatives are and to have a say;</li>
                                <li>Continue to support You through monthly check ins with and care plan revisits every 3 months.</li>
                            </ol>
                            <p>5.2  We will provide the Services to you from the commencement data set out in the Care Plan until the end date set out in the Care Plan or, if no end data is set out, until the Care Agreement is terminated in accordance with these Terms. </p>
                            <p>5.3. We will need certain personal information from you which is necessary for us to provide the Services. You agree to provide clear and accurate information including, but not limited to, Your physical and mental conditions, likes, dislikes, smoking preference, allergies and interests. If you do not, after being asked by us, provide us with this information, or you provide us with incomplete or incorrect information, we may suspend the Services by giving you written notice. You agree to inform us of a change of address, following this we may carry out a further assessment of Your needs. </p>
                            <p>5.4 If Your care is being arranged by a representative due to lack of capacity (in accordance with the provisions of the Mental Health Act 2005). You agree that we can discuss and identify Your care and Your Care Plan with your representative. Before we arrange care with a representative we will seek Your consent, or if you do not have sufficient capacity to provide consent, the consent of your relative. </p>
                            <p>5.5 After the creation of your Care Plan we will review on a 3 month basis thereafter or when it is apparent to Us that Your care needs have changed. We will endeavour to review Your Care Plan with You and Your family, and where applicable, any other appropriate external social or health care professional and take all reasonable steps to ensure you understand the Care Plan.</p>
                            <p>5.6 As part of Your care We may be able to offer additional services and support for which you may be changed an additional amount. If, following consultation with us, You would like to try any of Our additional services We may require that You enter into a separate agreement for these</p>
                            <p>5.7 If you needs change or increase to a level which cannot be met by Us, We will tell You without delay, and will endeavour to discuss alternative arrangements, and agree a mutually acceptable solution with You. We will continue to provide the Service to You during this period (unless We have given You notice to terminate under clause 6 and Our Agreement has been terminated)</p>
                            <p></p>

                            <h2>6. Fees, Cancellations and Refunds</h2>
                            <h3>Fees</h3>
                            <p>6.1 When you make a Visit booking with Age Abode (operating as edyn.care) you agree that you have the funds available to pay for the visit value. All fees for work must be made via Age Abode’s (operating as edyn.care); attempts to pay outside of Age Abode (operating as edym.care) will lead to sanctions not limited to immediate account suspension and termination of right to use the Platform.</p>

                            <p>6.2 The Fees payable by you for the Services are stated in the Care Plan. These Fees are calculated based on your care needs.</p>
                            <p>6.3 Fees for Visit are non-refundable (unless at least 48 hours notice of cancellation has been provided) and will be charged to you in accordance with the Company’s pricing schedule in force at the time a Visit is requested, which the Company may update from time to time on a going forward basis.</p>
                            <p>6.4 Payment will be taken on the Monday of each Week in respect of Services to be provided during the following Week. Age Abode (operating as edyn.care) will charge you for a Visit through the payment method specified in your account (e.g a credit card or debit card).</p>
                            <p>You agree to following:</p>
                            <ol className="alpha">
                                <li>Not to make payment to a Carer directly, or may any payment other than via our payment provider;</li>
                                <li>Acknowledge the investment we have made in the Platform and promoting it in order to offer you Carers, and undertaken not to enter into any direct agreement with any Carer that seeks to avoid the payment of our charges, or to receive care services from Carers we have introduced you to or offered, other than via the Platform (including after you have ceased using the Platform); and</li>
                                <li>Acknowledge than any breach of this section may result in the immediate termination of your account and right to use the Platform.</li>
                            </ol>
                            <p>6.5 We will always give us at least 4 weeks notice of any increase of Fees.</p>
                            <p>6.6 Services on Public Holidays will follow pricing structure as follows:</p>
                            <ol className="alpha">
                                <li>for Services charged on hourly basis will be increased by £7 (inclusive of VAT) per visit to be paid in addition to Fees in care plan agreed on introductory visit.</li>
                                <li>For Services charged on a daily or nightly or live-in basis will be increased by £85 (inclusive of VAT per day or night to be paid in addition to the Fee set out in care plan agreed on introductory visit, such increased rates being the “Public Holiday Fees”).</li>
                            </ol>
                            <p>6.7 If you do not make any payment due to us by the due date for payment we may charge interest to you on the overdue amount at the rate of 7% a year above the base lending rate of HSBC Bank plc from time to time. This interest will accrue on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or after judgement. You must pay Us interest together with any overdue amount. We reserve the right to charge you for our reasonable costs incurred in enforcing payment of overdue amount. </p>
                            <p>6.8 If you dispute an invoice in good faith and contact us to let us know, within 10 days after you have received an invoice that you dispute it, clause 6. 7 will not apply for the period of the dispute.</p>
                            <h3>Cancellations</h3>
                            <p>6.9 Age Abode (operating as edyn.care) encourages both You and carers to ensure cancellations are kept to a minimum.</p>
                            <p>6.10 In the case that a cancellation is necessary, you can cancel the Service at any time within 14 days of his Agreement (“Cancellation Period”) by letting the edyn.care team know in writing. If you wish to suspend your service, please get in contact with edyn.care team directly.</p>
                            <p>6.11 If you wish to cancel an an individual Visit You must give us at least 48 hours, unless exceptional circumstances can be proved - admission to hospital. If the 48 hour notice period isn’t adhered to, client will be billed and carer will be paid.</p>
                            <p>6.12 Once we have begun to provide the Services to you, we may cancel the Agreement at any time by:</p>
                            <ol className="alpha">
                                <li>Giving 14 days written notice for any reason; or</li>
                                <li>You do not pay fees mandate to us within 7 days of invoice; or</li>
                                <li>Immediately on written notice if a third party who has agreed to pay Your fees (or part of them) fails to pay Your Fees within 21 days of the data of invoice; or</li>
                                <li>After giving 7 days written notice that We are unable to meet Your needs; or </li>
                                <li>We perceive a significant risk of harm to the Carer; or </li>
                                <li>In the event of Your death, a relative or other authorised representative shall notify Us promptly. This Agreement will terminate immediately in the vent of Your death and Your estate will remain responsible for paying all and any outstanding fees in accordance with the terms of our Agreement. </li>
                            </ol>
                            <h3>Refunds</h3>
                            <p>6.13 Age Abode (operating as edyn.care) encourages both You and carers to ensure cancellations are kept to a minimum.</p>
                            <h2>7. Your home environment</h2>
                            <p>7. 1 As part of this agreement we expect you to provide a safe home environment and appropriate equipment to allow the Carer to carry out the Service. Including:</p>
                            <ol className="alpha">
                                <li>Providing domestic equipment - mops, iron board, irons etc;</li>
                                <li>Providing any equipments required to deliver Your care such as lifting and transfer aids, wheelchairs and other mobility aids; </li>
                                <li>Ensuring that any equipment is regularly maintained and inspected in accordance with  all relevant safety requirements </li>
                                <li>We are required to ensure that Your home and equipment is safe to use the provision of the Service. We will notify You if We find that Your Home or equipment is not safe and, where possible, assist You with making any necessary changes. </li>
                                <li>As entry plan for Your Home may be agreed with You, Your relative or Your authorised representative and if so will appear in the Care Plan. </li>
                            </ol>
                            <h2>8. Live-in Carers </h2>
                            <p>8.1 Legally, Live-in carers have various rights which include (without limitation) the following:</p>

                            <div className="indent">
                                <p>8.1.1  Require a bedroom of their own with suitable bathing and toilet facilities; and</p>
                                <p>8.1.2  A minimum 2 hour is required per day and be able to leave the Client’s home during that time. IF this is not suitable, arrangements will be to have cover over this time.. Please note that the break must be in the afternoon unless alternative time is arranged.  A minimum of 14 hours over a 7 day week is needed; and</p>
                                <p>8.1.3  Food for the duration of their stay. If you would rather not supply food and drinks, then we ask you advance £50.00 per week for sustenance.</p>
                            </div>
                            <p>8. 2  We will discuss these requirement with you in detail and record these in your Care Plan. </p>
                            <p>8. 3 If your live-in carer is disturbed more than twice during the night on a regular basis then we will reassess the care package and discuss the possibility of additional waking night care.</p>

                            <h2>9. Feedback & Complaints procedure</h2>
                            <p>9.1 We openly welcome all feedback, whether positive or negative. We believe that feedback is a valuable indicator of the quality of our service and give us information to learn from. It is our policy that all matters which disturb or upset our care recipients or our clients should be reported, recorded, and corrective action should be taken.</p>
                            <p>9.2 We encourage feedback from You or, if you lack sufficient capacity, a relative or other authorised representative can make a complaint or suggestion in relation to the Service. This procedure is described in the Service User Guide.</p>
                            <p>9.3 As part of our quality assurance procedure You may be asked to contribute to user satisfaction surveys. We may also ask you to participate in carer feedback. We ask you to:</p>
                            <ol className="alpha">
                                <li>Submit it in good faith and honest belief at all times;</li>
                                <li>Not submit any feedback that is unlawful, threatening, abusive, libellous pornographic, obscene, vulgar, indecent, offensive or which infringes on the intellectual property rights or other rights of any third party;</li>
                                <li>Not submit any feedback that contains any viruses and/or code that has contaminating or destructive elements; and/or</li>
                                <li>Not impersonate, or misrepresent an affiliation with, any person or entity. </li>
                            </ol>
                            <p>9.4 This information may be used by the Company to improve the Service. You agree to providing an exclusive, perpetual, royalty free worldwide right and licence for Us to publish, distribute and reproduce these surveys. You are not obliged to reply to satisfaction surveys or interviews. We will always request Your consent before We commence any Service monitoring in Your Home. </p>
                            <p>9.5 As part of Carer onboarding process and under legal requirements it may be necessary for a carer to observe, supervise or work with the Carers in Your Home. So that we can do so, you agree that the Carer may visit your home, provided that we give you reasonable notice. We will give you as much not as possible when this occurs. </p>
                            <h2>10. Confidentiality</h2>
                            <p>10.1 We may at time gather information about you when you are using our service. The ways ways we do this, the reasons why, and what you can do if you do not want us to hold and use your personal information, are all set out in our Privacy Policy. We believe the information we collect from You is Your data.</p>
                            <h2>11. Online records</h2>
                            <p>11.1 Our carers utilise their own phones to update Your records of the care You receive, any assistance with Your medication and messages. This information is stored on the Platform which you can access through Your client portal (provided on Your registration). </p>
                            <p>11.2  You may provide your login details to Your relatives or other authorised representatives but otherwise we ask You to keep Your login details safe and secure.  </p>
                            <h2>12. Liability & insurance</h2>
                            <p>12.1  If we fail to comply with the terms of the Agreement, we are responsible for loss or damage you suffer that is foreseeable result of our breach or our negligence, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if they were an obvious consequence of our breach or if they were contemplated by you and us at the time you and we entered in the Agreement. </p>
                            <p>12.2  We will not be liable for any breakages or damage caused by the Carer in your home unless this loss, clearly caused by the Carer, is not recoverable under your household insurance contents policy. If, during the provision of the Services in Your Home, We cause any damage to your Home. We will make good any damage save for where such damage is caused as a result of any pre-existing fault or damage or provision of any faulty equipment by You. You will be responsible for giving clear and adequate instructions for the use of items of electrical equipment in your home. </p>
                            <p>12.3 We strictly require each of the Carers to hold sufficient liability insurance policy at the minimum level as required by the then-current applicable laws. </p>
                            <p>12.5 You will ensure that, at all times during which the Services are being provided:</p>
                            <ol className="alpha">
                                <li>You have in place suitable building and content insurance to cover accidental damage to Your Home or its contents; and</li>
                                <li>Where the Services include Our carers driving Your motor vehicle, that You have suitable motor insurance to cover damage caused by the use of the motor vehicle by the Carers to passengers, Your vehicle and / or third parties or their property.</li>
                            </ol>

                            <p>12.5  We do not exclude or limit in any way our liability for:</p>
                            <ol className="alpha">
                                <li>Death or personal injury caused by our negligence;</li>
                                <li>Fraud or fraudulent misrepresentation;</li>
                                <li>Any matter for which would be unlawful for us to exclude or limit our liability or to attempt to do so</li>
                            </ol>
                            <p>12.6 Subject to sections 12. 1,2,3,4,5, our total aggregate liability to you under this Agreement shall be limited to the greater of (a) the amount you have paid us in the 12 months leading up to the incident or failure in questions; and (b) £5,000.</p>
                            <p>12.7  We will treat all use of the platform under your login details as use by you. </p>

                            <h2>13. Links</h2>
                            <p>13.1 Our service may include links to external sites, which may include links to third party offers and promotions. We include these to provide you with access to information, products or services that you may find useful or interesting. We are not responsible for the content of these sites or anything provided by them and do not guarantee that they will be continuously available.</p>
                            <p>13. 2 We may also suggest certain third party service providers. Such suggestions are not party to the Services We provide. Your use of such third party service providers is entirely at Your discretion and at Your own risk and any contract with such third party service provider will be solely between You and Them. We accept no responsibility for such uses of third party providers.</p>
                            <h2>14. Events outside our control</h2>
                            <p>14. 1 We will not be liable or responsible for any failure to perform or delay in doing so that may be caused by an event beyond our reasonable control including, without limitation, strikes, lockouts and other industrial disputes, breakdown of systems or network access, failure of our carers, flood, weather, flood, acts of terrorism or accident.</p>
                            <p>14.2 You will not be liable or responsible for any failure to perform or delay in performance of any of Your obligations for any reason beyond Your reasonable control.</p>

                            <h2>15. Obligations on Carers</h2>
                            <p>15. 1 As a Carer you accept that we may carry-out the following background checks on you:</p>
                            <ol>
                                <li>Enhanced DBS (Disclosure and Barring Service) checks;</li>
                                <li>Identity checks;</li>
                                <li>Right to work and documents check (including but not limited to; passport, driving license and visa);</li>
                                <li>Adverse history check; </li>
                                <li>Employment history checks; and </li>
                                <li>Reference checks, both professional and personal. </li>
                            </ol>
                            <p>15.2 Carers will provide us with any references and vetting information which we may require and you recognise that you will not become a Registered user until all checks have been satisfactorily completed by us. You further recognise that we may in our absolute discretion decline to register you if you fail our vetting checks, any stage of our application process or for any other reason. In Your in-person interview we will request evidence for application responses if they are not provided we will decline to register you.</p>
                            <p>15.3 You will provide accurate Registration Data and other information and keep the information you provide on the Platform up to date at all times including, in particular, your profile information and your availability to provide Care.</p>
                            <p>15.5 You will submit accurate expenses.</p>
                            <p>15.7. You will keep information about other Registered Users confidential.</p>
                            <p>15.8 You will abide by the “Carer Community Standards”.</p>

                            <h2>16. Privacy</h2>
                            <p>16.1 We may at times gather information about you when you are using our service. The ways we do this, the reasons why, and what you can do if you do not want us to hold and use your personal information, are all set out in our Privacy Policy. By using Age Abode (operating as edyn.care) services you consent that Age Abode (operating as edyn.care) may process information about you in accordance with our Privacy Policy (www.edyn.care/privacy).</p>

                            <h2>17. Severance</h2>
                            <p>18. 1 If any of these Terms is held by a court of law in any territory to be invalid, illegal or otherwise unenforceable, then, to the extent that it is held to be so, and with regard to the relevant territory only, that provision shall be severed and deleted from these Terms, provided that the remaining provisions of these Terms shall remain binding and enforceable and the severed provision shall also remain so in all other territories.</p>

                            <h2>18. Governing law and jurisdiction</h2>
                            <p>18. 1 These terms shall be governed by, and construed in accordance with, English law, and any disputes that may arise under or in relation to these Terms shall be subject to the exclusive jurisdiction of the courts of England.</p>
                            <h2>19. Offers</h2>
                            <p>19. 1 Any offer advertised via post of online is single-use and only valid for private individuals, not businesses, charities or other third parties who act as a refeffer. This offer does not apply to discharge services.</p>
                            <h2>20. General</h2>
                            <p>20.2 If we fail to enforce, or delay in enforcing, any of our rights under this Agreement, that does not result in a waiver of the rights concerned.</p>
                            <p>20.3 If any provision of the Agreement or any other terms incorporated by reference is found to be unenforceable, it shall not affect the enforceability of any other provisions they contain.</p>
                            <p>20.4 No terms are enforceable under the Contracts (Rights of Third Parties) Act 1999 by a person who is not a party to the agreement between us.</p>
                            <p>20.5 All notices given by you to us or vice-versa must be given by email or in writing to the address set out below. We may give notice to you at either the email or postal address you provide to us when registering.</p>
                            <h2>21. Contact information</h2>
                            <p>If you have any questions regarding the Terms of Services or the Service, please contact us at hello@edyn.care. </p>



                            <p>V.1.1 - 8 June 2018</p>

                        </div>
                    </section>
                </main >
                <Footer includeCTA={false} />
            </div >
        </>
    );
}
